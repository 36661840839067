.relate-badge {
  min-width: 100px !important;
  color: inherit;
}

.request-leave {
  background-color: #fff2cc;
  min-width: 80px !important;
  color: inherit;
}

.approve-leave {
  background-color: #92d050;
  min-width: 80px !important;
  color: inherit;
}

.deny-leave {
  background-color: #ff0000;
  min-width: 80px !important;
  color: inherit;
}

.change-leave {
  background-color: #9dc3e6;
  min-width: 80px !important;
  color: inherit;
}

.undo-leave {
  background-color: #f4b183;
  min-width: 80px !important;
  color: inherit;
}

.restart-leave,
.create-leave {
  background-color: #8faadc;
  min-width: 80px !important;
  color: inherit;
}

.disabled-grid-row {
  pointer-events: none;
  background: repeating-linear-gradient(
      45deg,
      rgba(127, 127, 127, 0.1),
      rgba(127, 127, 127, 0.1) 10px,
      rgba(127, 127, 127, 0.2) 10px,
      rgba(127, 127, 127, 0.2) 20px
  );
}

#LeaveOverview {
  .x-mask {
    background-image: none;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: default;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
  }
}

.detail-view-field {
  #leave_overview span {
    display: inline-block;
    vertical-align: middle;
  }
}

mat-panel-description.right-aligned {
  flex-grow: 0;
}

mat-grid-list mat-grid-tile .mat-grid-tile-content {
  justify-content: left;
}

.example-button-row .mat-progress-spinner,
.example-button-row .mat-spinner, {
  display: inline-block;
  vertical-align: middle;
}

.col-light {
  color: #333333 !important;
}

.col-dark {
  color: #020910 !important;
}

.leave-type-card {
  border-radius: 10px !important;
}

.responsive_table span.badge {
  margin: 0px 3px;
}
